<template>
    <div v-if="nfcSupported">
      <div v-if="selectedLangCode == null && languageMenuItems.length > 1" class="c-select-language-page">
          <ul class="c-pages-menu menu">
              <li class="c-select-language-page__menu-item menu__item link" v-for="language in this.languageMenuItems" :key="language.id">
                  <a href="#" class="c-select-language-page__link" role="link" @click.prevent="$xp.nfc.setAppLang(language)">
                      <div class="c-select-language-page__name">{{ language.langName }}</div>
                      <img :src="language.icon" :alt="language.langName" class="c-select-language-page__icon" aria-hidden="true">
                  </a>
              </li>
          </ul>
      </div>
      <div v-else-if="nfcCodeFound" class="c-nfc-page">
          <ul class="c-pages-menu">
              <li class="c-nfc-page__item" v-for="item in branches" :key="item.id">
                  <a href="#" class="c-nfc-page__link" v-if="item.children.length == 0" role="link" @click.prevent="$xp.nfc.goToPage(item)">
                      <div class="c-nfc-page__title">{{ item.title }}</div>
                  </a>
                  <a href="#" v-if="item.children.length" :class="(isInSelectedBranch(item) ? 'selected ' : '') + 'c-nfc-page__link'" role="link" @click.prevent="clickBranch(item)">
                      <div class="c-nfc-page__title">{{ item.title }}</div>
                  </a>
                  <ul v-if="item.children.length" class="c-pages-menu menu">
                      <li class="c-nfc-page__item" v-for="subitem in item.children" :key="subitem.id">
                          <a href="#" class="c-nfc-page__link" v-if="subitem.children.length == 0" role="link" @click.prevent="$xp.nfc.goToPage(subitem)">
                              <div :class="(isInSelectedBranch(item) ? 'selected ' : '') + 'c-nfc-page__title'">{{ subitem.title }}</div>
                          </a>
                          <div v-if="subitem.children.length" class="c-nfc-page__title">{{ subitem.title }}</div>

                          <ul v-if="subitem.children.length" class="c-pages-menu">
                              <!-- <li>too deep</li> -->
                                  <li class="c-nfc-page__item" v-for="subsubitem in subitem.children" :key="subsubitem.id">
                                    <a href="#" class="c-nfc-page__link" v-if="subsubitem.children.length == 0" role="link" @click.prevent="$xp.nfc.goToPage(subsubitem)">
                                        <div :class="(isInSelectedBranch(item) ? 'selected ' : '') + 'c-nfc-page__title'">{{ subsubitem.title }}</div>
                                    </a>
                                    <div v-if="subsubitem.children.length" class="c-nfc-page__title">{{ subsubitem.title }}</div>

                                    <!-- <ul v-if="subsubitem.children.length" class="c-pages-menu">
                                        <li>too deep</li>
                                    </ul> -->
                                </li>
                          </ul>
                      </li>
                  </ul>
              </li>
          </ul>
      </div>
      <div v-else-if="$route.query.nfcCode" class="c-nfc-page">
          <xp-section class="c-nfc-page__not-found">
              <div class="js-entry o-entry c-text-entry">
                  <div class="c-text-entry__content">
                      Dem NFC code {{ $route.query.nfcCode }} ist kein Inhalt zugeordnet.
                  </div>
              </div>
          </xp-section>
      </div>
      <div v-else class="c-nfc-page">
          <xp-section v-for="section in pageData.Sections" :key="section.ID">
              <dynamic-entry v-for="entry in section.entries" :entryData="entry" :key="entry.ID" :id="'entry-' + entry.ID"></dynamic-entry>
          </xp-section>
          <div class="c-nfc-page__nfc-ios-browser-hint" v-if="$xp.device.platform === 'browser' && pageData.Settings.nfcIosBrowserHint && $xp.device.isIPhoneOrPad">
            {{pageData.Settings.nfcIosBrowserHint}}
          </div>
      </div>
    </div>
    <div class="c-nfc-page__error-message" v-else>
      {{ $t('global.nfcNotAvailable') }}
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
import debug from 'debug'

const log = debug('xpedeo-core:xp-nfc-page')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  mixins: [pageMixin],
  data () {
    return {
      nfcCode: null,
      nfcSupported: true
    }
  },
  mounted () {
    log('xpnfcPage mounted', this, this.$router)
    if ((this.$xp.device.platform === 'ios')) {
      if (!this.$xp.nfc.isEnabled) {
        this.nfcSupported = false
        return
      } else {
        log('On iOS, nfc is enabled & supported')
        this.$xp.nfc.scanNdefIos()
      }
    }
    this.$xp.navigation.preventAutoHide()
    this.$xp.navigation.showNav()
    window.localStorage.setItem('nfcPageId', this.pageData.ID)
    let localStorageLangCode = window.localStorage.getItem('nfcLangCode')
    if (!localStorageLangCode) {
      const defaultSettingsLangCode = this.$xp.settings.getValue('content.langCode')
      window.localStorage.setItem('nfcLangCode', defaultSettingsLangCode)
      localStorageLangCode = defaultSettingsLangCode
    }
    this.$xp.nfc.selectedLangCode = localStorageLangCode
    this.$xp.nfc.selectedBranchIds = window.localStorage.getItem('nfcSelectedBranchIds') || []
    this.$xp.nfc.startNfcNavigation()
  },
  methods: {
    clickBranch (item) {
      if (item.children.length) {
        this.$xp.nfc.goToPage(item.children[0])
      }
    },
    isInSelectedBranch (item) {
      return this.$xp.nfc.selectedBranchIds.includes(item.id) || this.$xp.nfc.selectedBranchIds.includes(item.ancestorId)
    }
  },
  computed: {
    nfcData () {
      return this.pageData.nfc
    },
    branches () {
      return this.$xp.nfc.branches
    },
    languageMenuItems () {
      const items = []
      this.pageData.LanguageVirtualFolders.forEach((item) => {
        items.push({
          id: item.ID,
          langName: item.LangName,
          langCode: item.LangCode,
          langId: item.LangID,
          path: item.Path,
          icon: item.Icon
        })
      })
      return items
    },
    nfcCodeFound () {
      return this.$xp.nfc.nfcCodeFound
    },
    selectedLangCode () {
      return this.$xp.nfc.selectedLangCode
    }
  },
  watch: {
    $route: {
      handler: function (newValue, oldValue) {
        log('in xpnfcpage route watcher', newValue, oldValue)
        if ((newValue.path === oldValue.path && newValue.query !== null) || newValue.query.nfcCode) {
          log('starting navigation')
          this.$xp.nfc.startNfcNavigation()
        }
      }
    }
  }
}
</script>
